import '../scss/style.scss'
import '../scss/suitcss.scss'

import Swiper from 'swiper'
import Headroom from 'headroom.js'
import {Fancybox} from '@fancyapps/ui'
import 'lazysizes'
import 'mediaelement'
import lightGallery from 'lightgallery.js'

const bodyScrollLock = require('body-scroll-lock')
import * as Cookie from 'tiny-cookie'
import 'fslightbox'

import lottie from 'lottie-web'

import {gsap} from 'gsap'
import {Flip} from 'gsap/Flip'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(Flip)
gsap.registerPlugin(ScrollTrigger)

import collapse from '@alpinejs/collapse'
import Alpine from 'alpinejs'

window.Alpine = Alpine
Alpine.plugin(collapse)

import autoAnimate from '@formkit/auto-animate'

const productFilters = document.querySelectorAll('.productFilter-products')
productFilters.forEach((filter) => {
    autoAnimate(filter)
})

// import './datasheet'
// import './gtageventtracking'
var s = []
let tagsList

function slider(element, options) {
    var defaultOptions = {
        simulateTouch: false,
        a11y: {
            notificationClass: 'Slider-notification'
        },
        // Classes
        noSwipingClass: 'has-noSwiping',
        containerModifierClass: 'Slider--',
        slideClass: 'Slider-item',
        slideBlankClass: 'is-blank',
        slideActiveClass: 'is-active',
        slideDuplicateActiveClass: 'is-duplicate-active',
        slideVisibleClass: 'is-visible',
        slideDuplicateClass: 'is-duplicate',
        slideNextClass: 'is-next',
        slideDuplicateNextClass: 'is-duplicate is-next',
        slidePrevClass: 'is-prev',
        slideDuplicatePrevClass: 'is-duplicate is-prev',
        wrapperClass: 'Slider-items'
    }

    if (options != undefined) {
        if (options.navigation) {
            var optionNavigation = {
                nextEl: options.navigation.nextEl || '.Slider-button--next',
                prevEl: options.navigation.prevEl || '.Slider-button--prev',
                hideOnClick:
                    options.navigation.hideOnClick == true ? true : false
            }

            options.navigation = {
                nextEl: optionNavigation.nextEl,
                prevEl: optionNavigation.prevEl,
                hideOnClick: optionNavigation.hideOnClick,
                disabledClass: 'is-disabled',
                hiddenClass: 'is-hidden'
            }
        }

        if (options.pagination) {
            var optionsPagination = {
                el: options.pagination.el || '.Slider-pagination',
                type: options.pagination.type || 'bullets',
                bulletElement: options.pagination.bulletElement || 'span',
                dynamicBullets:
                    options.pagination.dynamicBullets == true ? true : false,
                dynamicMainBullets: options.pagination.dynamicMainBullets || 1,
                hideOnClick:
                    options.pagination.hideOnClick == true ? true : false,
                clickable: options.pagination.clickable == false ? false : true,
                progressbarOpposite:
                    options.pagination.progressbarOpposite == true
                        ? true
                        : false,
                formatFractionCurrent:
                    options.pagination.formatFractionCurrent ||
                    ((number) => number),
                formatFractionTotal:
                    options.pagination.formatFractionTotal ||
                    ((number) => number),
                renderBullet: options.pagination.renderBullet || null,
                renderFraction: options.pagination.renderFraction || null,
                renderProgressbar: options.pagination.renderProgressbar || null,
                renderCustom: options.pagination.renderCustom || null
            }

            options.pagination = {
                el: optionsPagination.el,
                type: optionsPagination.type,
                bulletElement: optionsPagination.bulletElement,
                dynamicBullets: optionsPagination.dynamicBullets,
                dynamicMainBullets: optionsPagination.dynamicMainBullets,
                hideOnClick: optionsPagination.hideOnClick,
                clickable: optionsPagination.clickable,
                progressbarOpposite: optionsPagination.progressbarOpposite,
                formatFractionCurrent: optionsPagination.formatFractionCurrent,
                formatFractionTotal: optionsPagination.formatFractionTotal,
                renderBullet: optionsPagination.renderBullet,
                renderFraction: optionsPagination.renderFraction,
                renderProgressbar: optionsPagination.renderProgressbar,
                renderCustom: optionsPagination.renderCustom,
                bulletClass: 'Slider-pagination-item',
                bulletActiveClass: 'is-active',
                modifierClass: 'Slider-pagination--',
                currentClass: 'is-current',
                totalClass: 'is-total',
                hiddenClass: 'is-hidden',
                progressbarFillClass: 'Slider-pagination-fill',
                clickableClass: 'is-clickable',
                lockClass: 'is-locked'
            }
        }

        if (options.scrollbar) {
            var optionsScrollbar = {
                el: options.scrollbar.el || '.Slider-scrollbar',
                hide: options.scrollbar.hide == false ? false : true,
                draggable: options.scrollbar.draggable == true ? true : false,
                snapOnRelease:
                    options.scrollbar.snapOnRelease == false ? false : true,
                dragSize: options.scrollbar.dragSize || 'auto'
            }

            options.scrollbar = {
                el: optionsScrollbar.el,
                hide: optionsScrollbar.hide,
                draggable: optionsScrollbar.draggable,
                snapOnRelease: optionsScrollbar.snapOnRelease,
                dragSize: optionsScrollbar.dragSize,
                lockClass: 'is-locked',
                dragClass: 'Slider-scrollbar-drag'
            }
        }

        if (options.lazy) {
            var optionsLazy = {
                loadPrevNext: options.lazy.loadPrevNext == true ? true : false,
                loadPrevNextAmount: options.lazy.loadPrevNextAmount || 1,
                loadOnTransitionStart:
                    options.lazy.loadOnTransitionStart == true ? true : false
            }

            options.lazy = {
                loadPrevNext: optionsLazy.loadPrevNext,
                loadPrevNextAmount: optionsLazy.loadPrevNextAmount,
                loadOnTransitionStart: optionsLazy.loadOnTransitionStart,
                elementClass: 'is-lazy',
                loadingClass: 'is-loading',
                loadedClass: 'is-loaded',
                preloaderClass: 'has-preloader'
            }
        }

        if (options.thumbs) {
            var optionsThumbs = {
                swiper: options.thumbs.swiper || null
            }

            options.thumbs = {
                swiper: optionsThumbs.swiper,
                slideThumbActiveClass: 'is-active',
                thumbsContainerClass: 'Swiper--thumbnails'
            }
        }

        if (options.zoom) {
            var optionsZoom = {
                maxRatio: options.zoom.maxRatio || 3,
                minRatio: options.zoom.minRatio || 1,
                toggle: options.zoom.toggle == false ? false : true
            }

            options.zoom = {
                maxRatio: optionsZoom.maxRatio,
                minRatio: optionsZoom.minRatio,
                toggle: optionsZoom.numbertoggle,
                containerClass: 'Slider--zoom',
                zoomedSlideClass: 'is-zoomed'
            }
        }

        if (options.a11y) {
            optionsA11y = {
                enabled: options.a11y.enabled == true ? true : false,
                prevSlideMessage:
                    options.a11y.prevSlideMessage || 'Previous slide',
                nextSlideMessage: options.a11y.nextSlideMessage || 'Next slide',
                firstSlideMessage:
                    options.a11y.firstSlideMessage || 'This is the first slide',
                lastSlideMessage:
                    options.a11y.lastSlideMessage || 'This is the last slide',
                paginationBulletMessage:
                    options.a11y.paginationBulletMessage ||
                    'Go to slide {{index}}'
            }

            options.a11y = {
                enabled: optionsA11y.enabled,
                prevSlideMessage: optionsA11y.prevSlideMessage,
                nextSlideMessage: optionsA11y.nextSlideMessage,
                firstSlideMessage: optionsA11y.firstSlideMessage,
                lastSlideMessage: optionsA11y.lastSlideMessage,
                paginationBulletMessage: optionsA11y.paginationBulletMessage,
                notificationClass: 'Slider-notification'
            }
        }
    }

    options = jQuery.extend(defaultOptions, options)
    s[element] = new Swiper(element, options)

    window.dispatchEvent(new Event('resize'))
}

const asyncLoadSiteFonts = () => {
    if (document.documentElement.className.indexOf('fonts-loaded') < 0) {
        var fontello = new FontFaceObserver('fontello', {})
        var BrandonRegular = new FontFaceObserver('brandon', {
            weight: 400
        })
        var BrandonRegularItalic = new FontFaceObserver('brandon', {
            weight: 400,
            style: 'italic'
        })
        var BrandonBold = new FontFaceObserver('brandon', {
            weight: 700
        })
        var Esfera = new FontFaceObserver('esfera', {
            weight: 400
        })

        Promise.all([
            fontello.load(''),
            BrandonRegular.load(),
            BrandonRegularItalic.load(),
            BrandonBold.load(),
            Esfera.load()
        ]).then(function () {
            document.documentElement.className += ' fonts-loaded'
            Cookie.set('fonts-loaded', 1, {expires: '7D', secure: true})
        })
    }
}

const forEachPolyfill = () => {
    if ('NodeList' in window && !NodeList.prototype.forEach) {
        console.info('polyfill for IE11')
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this)
            }
        }
    }
}

const form = () => {
    // File field
    $('.Form-field--file').each(function () {
        var $input = $(this).find('input'),
            $label = $(this).find('.Form-indicator'),
            labelVal = $(this).data('file-text')

        $input.on('change', function (e) {
            var fileName = ''
            var label = $(this)
                .closest('.Form-field--file')
                .find('.Form-indicator')[0]

            if (this.files && this.files.length > 1) {
                fileName = (
                    label.getAttribute('data-file-multiple') || ''
                ).replace('{count}', this.files.length)
            } else if (e.target.value) {
                fileName = e.target.value.split('\\').pop()
            }

            if (fileName) {
                $label.attr('data-file-text', fileName)
            } else {
                $label.attr('data-file-text', labelVal)
            }
        })
    })

    // Floating fields
    $('.Form-field').each(function () {
        var floatContainer = $(this).closest('.Form-item')

        $(this).on('focus', function () {
            floatContainer.addClass('is-active')
        })

        $(this).on('focusout', function () {
            var floatValue = $(this).val().length

            if (floatValue === 0) {
                floatContainer.removeClass('is-active')
            }
        })
    })
}

const gallery = () => {
    // Gallery
    var g = []
    const template = `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
        <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
        </div>

        <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
                <div class="pswp__counter"></div>

                <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                <button class="pswp__button pswp__button--share" title="Share"></button>
                <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                <div class="pswp__preloader">
                    <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div class="pswp__share-tooltip"></div>
            </div>

            <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
            </button>

            <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
            </button>

            <div class="pswp__caption">
                <div class="pswp__caption__center"></div>
            </div>
        </div>
    </div>
</div>
`

    function gallery(element, options) {
        var defaultOptions = {
            bgOpacity: 0.8,
            shareEl: false,
            fullscreenEl: false,
            closeOnScroll: false
        }

        // Get slides
        var parseThumbnailElements = function (el) {
            var thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                item

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]

                if (figureEl.nodeType !== 1) {
                    continue
                }

                linkEl = figureEl.children[0]

                // create slide object
                item = {
                    src: linkEl.getAttribute('href'),
                    w: linkEl.getAttribute('data-width'),
                    h: linkEl.getAttribute('data-height')
                }

                if (figureEl.children.length > 1) {
                    item.title = figureEl.children[1].innerHTML
                }

                if (linkEl.children.length > 0) {
                    item.msrc = linkEl.children[0].getAttribute('src')
                }

                item.el = figureEl
                items.push(item)
            }

            return items
        }

        // Find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn))
        }

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function (e) {
            e = e || window.event
            e.preventDefault ? e.preventDefault() : (e.returnValue = false)

            var eTarget = e.target || e.srcElement

            // find root element of slide
            var clickedListItem = closest(eTarget, function (el) {
                return el.tagName && el.tagName.toUpperCase() === 'FIGURE'
            })

            if (!clickedListItem) {
                return
            }

            // Set template
            var pswp = document.querySelectorAll('.pswp')[0]

            if (!pswp) {
                document
                    .querySelector('body')
                    .insertAdjacentHTML('beforeend', template)
            }

            // Find index
            var clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex
                    break
                }

                nodeIndex++
            }

            // Open PhotoSwipe if valid index found
            if (index >= 0) {
                openPhotoSwipe(index, clickedGallery)
            }

            return false
        }

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function () {
            var hash = window.location.hash.substring(1),
                params = {}

            if (hash.length < 5) {
                return params
            }

            var vars = hash.split('&')
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue
                }

                var pair = vars[i].split('=')
                if (pair.length < 2) {
                    continue
                }

                params[pair[0]] = pair[1]
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10)
            }

            return params
        }

        var openPhotoSwipe = function (
            index,
            galleryElement,
            disableAnimation,
            fromURL
        ) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
                items

            items = parseThumbnailElements(galleryElement)

            options = jQuery.extend(defaultOptions, options)
            options.galleryUID = galleryElement.getAttribute('data-pswp-uid')
            options.getThumbBoundsFn = function (index) {
                var thumbnail = items[index].el.getElementsByTagName('img')[0],
                    pageYScroll =
                        window.pageYOffset ||
                        document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect()

                return {
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                }
            }

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (var j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j
                            break
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1
                }
            } else {
                options.index = parseInt(index, 10)
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0
            }

            // Pass data to PhotoSwipe and initialize it
            if (pswpElement) {
                g[element] = new PhotoSwipe(
                    pswpElement,
                    PhotoSwipeUI_Default,
                    items,
                    options
                )
                g[element].init()
            }
        }

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(element)

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1)
            galleryElements[i].onclick = onThumbnailsClick
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash()
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(
                hashData.pid,
                galleryElements[hashData.gid - 1],
                true,
                true
            )
        }
    }
}

const media = () => {
    mejs.i18n.language(jQuery('html').attr('lang'))
    $('video, audio').mediaelementplayer({
        classPrefix: 'Media-',
        defaultVideoWidth: '100%',
        defaultVideoHeight: '100%'
    })
}

const tabHandler = () => {
    // Handle tabs gracefully as per https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.documentElement.classList.add('is-tabbing')

            window.removeEventListener('keydown', handleFirstTab)
            window.addEventListener('mousedown', handleMouseDownOnce)
        }
    }

    function handleMouseDownOnce() {
        document.documentElement.classList.remove('is-tabbing')

        window.removeEventListener('mousedown', handleMouseDownOnce)
        window.addEventListener('keydown', handleFirstTab)
    }

    window.addEventListener('keydown', handleFirstTab)
}

const sliderInit = () => {
    const sliders = document.querySelectorAll('.Block--slider')
    sliders.forEach((sliderElem) => {
        const swiperElement = sliderElem.querySelector('.Slider')

        const nextBtn = sliderElem.querySelector('.Slider-buttonSlider--next')
        const prevBtn = sliderElem.querySelector('.Slider-buttonSlider--prev')
        const settings = {
            loop: false,
            spaceBetween: 0,
            autoplay: {
                delay: 5000
            },
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn
            },
            slidesPerView: 1,
            autoHeight: true
            // simulateTouch: false,
        }
        slider(swiperElement, settings)
    })

    const quoteSliders = document.querySelectorAll('.Block--quotes')
    quoteSliders.forEach((sliderElem) => {
        const swiperElement = sliderElem.querySelector('.js-Quoteslider')

        if (swiperElement) {
            const nextBtn = sliderElem.querySelector(
                '.Slider-buttonQuotes--next'
            )
            const prevBtn = sliderElem.querySelector(
                '.Slider-buttonQuotes--prev'
            )
            const settings = {
                loop: true,
                autoplay: true,
                delay: 4500,
                spaceBetween: 0,
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn
                },
                slidesPerView: 1,
                autoHeight: true,
                simulateTouch: false
            }
            slider(swiperElement, settings)
        }
    })

    const timelineSliders = document.querySelectorAll('.Block--timeline')
    timelineSliders.forEach((sliderElem) => {
        const swiperElement = sliderElem.querySelector('.Slider')

        const nextBtn = sliderElem.querySelector('.Slider-buttonTimeline--next')
        const prevBtn = sliderElem.querySelector('.Slider-buttonTimeline--prev')

        const settings = {
            loop: false,
            spaceBetween: 0,
            navigation: {
                nextEl: '.Slider-buttonTimeline--next',
                prevEl: '.Slider-buttonTimeline--prev'
            },
            autoplay: {
                delay: 5000
            },
            slidesPerView: 1,
            autoHeight: true,
            simulateTouch: false
        }
        slider(swiperElement, settings)

        if (jQuery('.js-timelineItem').length > 0) {
            var mySlider = swiperElement.swiper
            mySlider.autoplay = {}

            jQuery('.js-timelineItem').on('click', function (event) {
                event.preventDefault()
                event.stopPropagation()

                let slide = jQuery(this).data('slide')
                if (mySlider) {
                    mySlider.slideTo(slide)
                    jQuery('.js-timelineItem').removeClass('is-active')
                    jQuery(this).addClass('is-active')
                }
            })

            mySlider.on('slideChange', function () {
                setTimeout(function () {
                    jQuery('.js-timelineItem').removeClass('is-active')
                    jQuery(
                        '.js-timelineItem[data-slide="' +
                        mySlider.activeIndex +
                        '"]'
                    ).addClass('is-active')
                }, 100)
            })
        }
    })
}

const scrollTo = () => {
    // ScrollTo
    if (jQuery('.js-scrollTo').length > 0) {
        jQuery('.js-scrollTo').click(function (event) {
            event.stopPropagation()
            event.preventDefault()

            var target = jQuery(this).data('target')
            if (target) {
                jQuery('html, body').animate(
                    {
                        scrollTop: jQuery(target).offset().top
                    },
                    500
                )
            }
        })
    }
}

const tiles = () => {
    function fixHeight() {
        jQuery('.js-tileBottom').each(function () {
            jQuery(this)
                .parents('.js-tile')
                .find('.js-tileImageWrapper')
                .css('height', this.offsetHeight + 'px')
        })
    }

    fixHeight()

    window.addEventListener('resize', function () {
        debounceFunction(fixHeight, 300)
    })

    var timerId
    var debounceFunction = function (func, delay) {
        // Cancels the setTimeout method execution
        clearTimeout(timerId)

        // Executes the func after delay time.
        timerId = setTimeout(func, delay)
    }
}

const map = () => {
    if (jQuery('#js-customMap').length > 0) {
        var Locations = {
            initialize: function (lat, lng) {
                this.map = null
                this.markers = []
                this.infoWindow = null
                this.latlngBounds = null
                this.lat = lat
                this.lng = lng
                this.currentSite =
                    jQuery('#js-customMap').data('current-site') ?? 'solidor'

                this.createMap()
            },
            setCenter: function () {
                let w = window.innerWidth
                let center = new google.maps.LatLng(
                    parseFloat(Locations.lat) + 0.001,
                    parseFloat(Locations.lng)
                )
                if (w > 768) {
                    center = new google.maps.LatLng(
                        parseFloat(Locations.lat) + 0.001,
                        parseFloat(Locations.lng)
                    )
                } else if (w < 767 && w > 400) {
                    center = new google.maps.LatLng(
                        parseFloat(Locations.lat) - 0.002,
                        parseFloat(Locations.lng)
                    )
                } else {
                    center = new google.maps.LatLng(
                        parseFloat(Locations.lat) - 0.0025,
                        parseFloat(Locations.lng)
                    )
                }

                Locations.map.setCenter(center)
            },
            createMap: function (obj) {
                var self = this

                var styles = {
                    red_and_green: [
                        {
                            featureType: 'landscape',
                            stylers: [
                                {lightness: 16},
                                {hue: '#ff001a'},
                                {saturation: -61}
                            ]
                        },
                        {
                            featureType: 'road.highway',
                            stylers: [{hue: '#ff0011'}, {lightness: 53}]
                        },
                        {
                            featureType: 'poi.park',
                            stylers: [{hue: '#00ff91'}]
                        },
                        {
                            elementType: 'labels',
                            stylers: [{lightness: 63}, {hue: '#ff0000'}]
                        },
                        {featureType: 'water', stylers: [{hue: '#0055ff'}]},
                        {
                            featureType: 'road',
                            elementType: 'labels',
                            stylers: [{visibility: 'off'}]
                        }
                    ],
                    girly: [
                        {
                            featureType: 'administrative',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#444444'}]
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'all',
                            stylers: [{color: '#f2f2f2'}]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'all',
                            stylers: [{saturation: -100}, {lightness: 45}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'all',
                            stylers: [{visibility: 'simplified'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#ff6a6a'},
                                {lightness: '0'}
                            ]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.icon',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'all',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'geometry.fill',
                            stylers: [{color: '#ff6a6a'}, {lightness: '75'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'labels.icon',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'road.local',
                            elementType: 'geometry.fill',
                            stylers: [{lightness: '75'}]
                        },
                        {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'transit.line',
                            elementType: 'all',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'transit.station.bus',
                            elementType: 'all',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'transit.station.rail',
                            elementType: 'all',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'transit.station.rail',
                            elementType: 'labels.icon',
                            stylers: [
                                {weight: '0.01'},
                                {hue: '#ff0028'},
                                {lightness: '0'}
                            ]
                        },
                        {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [
                                {visibility: 'on'},
                                {color: '#80e4d8'},
                                {lightness: '25'},
                                {saturation: '-23'}
                            ]
                        }
                    ],
                    light_dream: [
                        {
                            featureType: 'landscape',
                            stylers: [
                                {hue: '#FFBB00'},
                                {saturation: 43.400000000000006},
                                {lightness: 37.599999999999994},
                                {gamma: 1}
                            ]
                        },
                        {
                            featureType: 'road.highway',
                            stylers: [
                                {hue: '#FFC200'},
                                {saturation: -61.8},
                                {lightness: 45.599999999999994},
                                {gamma: 1}
                            ]
                        },
                        {
                            featureType: 'road.arterial',
                            stylers: [
                                {hue: '#FF0300'},
                                {saturation: -100},
                                {lightness: 51.19999999999999},
                                {gamma: 1}
                            ]
                        },
                        {
                            featureType: 'road.local',
                            stylers: [
                                {hue: '#FF0300'},
                                {saturation: -100},
                                {lightness: 52},
                                {gamma: 1}
                            ]
                        },
                        {
                            featureType: 'water',
                            stylers: [
                                {hue: '#0078FF'},
                                {saturation: -13.200000000000003},
                                {lightness: 2.4000000000000057},
                                {gamma: 1}
                            ]
                        },
                        {
                            featureType: 'poi',
                            stylers: [
                                {hue: '#00FF6A'},
                                {saturation: -1.0989010989011234},
                                {lightness: 11.200000000000017},
                                {gamma: 1}
                            ]
                        }
                    ],
                    simple_light: [
                        {
                            featureType: 'administrative',
                            elementType: 'all',
                            stylers: [{visibility: 'simplified'}]
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#fcfcfc'}
                            ]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#fcfcfc'}
                            ]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#dddddd'}
                            ]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#dddddd'}
                            ]
                        },
                        {
                            featureType: 'road.local',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#eeeeee'}
                            ]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [
                                {visibility: 'simplified'},
                                {color: '#dddddd'}
                            ]
                        }
                    ],
                    pinkish_grey: [
                        {
                            featureType: 'administrative',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#0c0b0b'}]
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'all',
                            stylers: [{color: '#f2f2f2'}]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'all',
                            stylers: [{saturation: -100}, {lightness: 45}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#090909'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'all',
                            stylers: [{visibility: 'simplified'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'labels.icon',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [
                                {color: '#d4e4eb'},
                                {visibility: 'on'}
                            ]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry.fill',
                            stylers: [
                                {visibility: 'on'},
                                {color: '#fef7f7'}
                            ]
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#9b7f7f'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{color: '#fef7f7'}]
                        }
                    ],
                    red_hat_antwerp: [
                        {
                            featureType: 'administrative',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#444444'}]
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'all',
                            stylers: [{color: '#f2f2f2'}]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'poi.business',
                            elementType: 'geometry.fill',
                            stylers: [{visibility: 'on'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'all',
                            stylers: [{saturation: -100}, {lightness: 45}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'all',
                            stylers: [{visibility: 'simplified'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'labels.icon',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [
                                {color: '#b4d4e1'},
                                {visibility: 'on'}
                            ]
                        }
                    ],
                    cool_grey: [
                        {
                            featureType: 'administrative',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#444444'}]
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'all',
                            stylers: [{color: '#f2f2f2'}]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'all',
                            stylers: [{saturation: -100}, {lightness: 45}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'all',
                            stylers: [{visibility: 'simplified'}]
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'labels.icon',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [{visibility: 'off'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [
                                {color: '#d3d3d3'},
                                {visibility: 'on'}
                            ]
                        }
                    ]
                }

                this.map = new google.maps.Map(
                    document.getElementById('js-customMap'),
                    {
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        draggable: true,
                        scrollwheel: false,
                        center: new google.maps.LatLng(
                            parseFloat(this.lat) + 0.001,
                            parseFloat(this.lng)
                        ),
                        zoom: 8,
                        disableDefaultUI: true,
                        styles: styles.cool_grey
                    }
                )

                this.setCenter()

                this.addMarker(this.lat, this.lng)

                google.maps.event.addDomListener(window, 'resize', function () {
                    debounceFunction(self.setCenter, 200)
                })
            },
            addMarker: function (lat, lng) {
                var markerImage = new google.maps.MarkerImage(
                    '/assets/img/icons/pin-' + this.currentSite + '.svg',
                    null,
                    null,
                    null,
                    new google.maps.Size(32, 65)
                )
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(
                        parseFloat(lat),
                        parseFloat(lng)
                    ),
                    title: '',
                    icon: markerImage,
                    map: this.map
                })

                return marker
            }
        }

        function initMap(lat, lng) {
            Locations.initialize(lat, lng)
        }

        let token = jQuery('#js-customMap').data('token')
        if (token && token != '') {
            jQuery.ajax({
                url:
                    'https://maps.googleapis.com/maps/api/js?key=' +
                    token +
                    '&sensor=false&libraries=geometry,places',
                dataType: 'script',
                success: function (data) {
                    if (jQuery('#js-customMap').length > 0) {
                        let lat = jQuery('#js-customMap').data('lat')
                        let lng = jQuery('#js-customMap').data('lng')
                        initMap(lat, lng)
                    }
                }
            })
        }
    }

    var timerId
    var debounceFunction = function (func, delay) {
        // Cancels the setTimeout method execution
        clearTimeout(timerId)

        // Executes the func after delay time.
        timerId = setTimeout(func, delay)
    }
}

const modal = () => {
    /* Modal */
    ;(function () {
        if (jQuery('.js-showModal').length > 0) {
            var parent, $thisModal

            jQuery('.js-showModal').click(function (event) {
                event.stopPropagation()
                event.preventDefault()

                var id = jQuery(this).data('modal')
                var sbWidth = getScrollbarWidth()
                $thisModal = jQuery('.js-modal[data-id="' + id + '"]')
                parent = $thisModal.parent()

                if (this.classList.contains('js-newDatasheets')) {
                    if (!Cookie.get('datasheet')) {
                        jQuery('body').append($thisModal.detach())
                        setTimeout(function () {
                            jQuery('html')
                                .css('paddingRight', sbWidth)
                                .addClass('hide-scroll')

                            jQuery('.js-modal').removeClass('is-active')

                            $thisModal
                                .css('paddingRight', sbWidth)
                                .addClass('is-active')
                        }, 100)
                    }
                } else {
                    setTimeout(function () {
                        jQuery('html')
                            .css('paddingRight', sbWidth)
                            .addClass('hide-scroll')

                        jQuery('.js-modal').removeClass('is-active')

                        $thisModal
                            .css('paddingRight', sbWidth)
                            .addClass('is-active')
                    }, 100)
                }
                // bodyScrollLock.disableBodyScroll($thisModal.get(0));

                const swiperElement = $thisModal.find('.Slider')

                const nextBtn = swiperElement.find('.Slider-button--next')
                const prevBtn = swiperElement.find('.Slider-button--prev')

                const settings = {
                    simulateTouch: false,
                    navigation: true,
                    pagination: true,
                    spaceBetween: 20,
                    autoHeight: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: false,
                        loadOnTransitionStart: false
                    }
                }
                // new Swiper(swiperElement,settings)
                slider(swiperElement, settings)
            })

            jQuery('.Modal-inner').click(function (event) {
                if (jQuery(event.target).is('.Modal-inner')) {
                    event.preventDefault()
                    event.stopPropagation()

                    closeModal(jQuery(this).closest('.Modal'))
                    return false
                }
            })

            jQuery('.js-closeModal').click(function (event) {
                event.preventDefault()
                event.stopPropagation()

                closeModal(jQuery(this).parents('.js-modal'))
            })

            jQuery(document).on('keyup', function (event) {
                if (event.keyCode == 27) {
                    closeModal()
                }
            })
        }

        function getScrollbarWidth() {
            return window.innerWidth - document.documentElement.clientWidth
        }

        function closeModal(element) {
            if ($thisModal == undefined) {
                return false
            }

            setTimeout(function () {
                jQuery('html').removeAttr('style')
                jQuery('.js-modal').removeAttr('style')
                jQuery('html').removeClass('hide-scroll')
                jQuery(parent).append($thisModal.detach())
            }, 200)

            if (element) {
                element.removeClass('is-active')
            } else {
                jQuery('.js-modal').removeClass('is-active')
            }

            bodyScrollLock.enableBodyScroll($thisModal.get(0))
        }
    })()
}

const nav = () => {
    function toggleNavigation(element) {
        var $nav = jQuery(element)
        var $navToggle = $nav.find('> .Navigation-toggle')
        var $parent = jQuery('html')
        var activeClass = 'show-nav'

        $navToggle.on('click', function () {
            if ($parent.hasClass(activeClass)) {
                bodyScrollLock.enableBodyScroll($nav.find('.Nav--main').get(0))
                $parent.removeClass(activeClass)
            } else {
                bodyScrollLock.disableBodyScroll($nav.find('.Nav--main').get(0))
                $parent.addClass(activeClass)
            }
        })
    }

    toggleNavigation('.Navigation')

    if (jQuery('.js-navLink').length > 0) {
        jQuery('.js-openSubMenu').on('click', function (event) {
            event.preventDefault()
            event.stopPropagation()

            let $link = jQuery(this).parents('.js-navLink')
            if ($link.hasClass('is-closed')) {
                $link.removeClass('is-closed')
                $link.addClass('is-open')
            } else {
                $link.removeClass('is-open')
                $link.addClass('is-closed')
            }
        })
    }
}

const banner = () => {
    const bannerCarousels = Array.from(
        document.querySelectorAll('.js-banner-carousel')
    )

    bannerCarousels.forEach((bannerCarousel) => {
        new Swiper(bannerCarousel, {
            // eslint-disable-line no-new
            autoplay: {
                delay: 5000
            },
            centeredSlides: true,
            centeredSlidesBounds: true,
            loop: false,
            observer: true,
            observeSlideChildren: true,
            slidesPerView: 1,
            speed: 1000,
            watchOverflow: true,
            autoHeight: true,
            grabCursor: false,
            noSwipingClass: 'has-noSwiping',
            containerModifierClass: 'Slider--',
            slideClass: 'Slider-item',
            slideBlankClass: 'is-blank',
            slideActiveClass: 'is-active',
            slideDuplicateActiveClass: 'is-duplicate-active',
            slideVisibleClass: 'is-visible',
            slideDuplicateClass: 'is-duplicate',
            slideNextClass: 'is-next',
            slideDuplicateNextClass: 'is-duplicate is-next',
            slidePrevClass: 'is-prev',
            slideDuplicatePrevClass: 'is-duplicate is-prev',
            wrapperClass: 'Slider-items'
        })
    })

    const bannerCarouselsNew = Array.from(
        document.querySelectorAll('.js-banner-carouselNew')
    )

    bannerCarouselsNew.forEach((bannerCarousel) => {
        new Swiper(bannerCarousel, {
            // eslint-disable-line no-new
            autoplay: {
                delay: 5000
            },
            centeredSlides: true,
            centeredSlidesBounds: true,
            observer: true,
            observeSlideChildren: true,
            slidesPerView: 1,
            speed: 1000,
            watchOverflow: true,
            autoHeight: true,
            grabCursor: false,
            noSwipingClass: 'has-noSwiping',
            containerModifierClass: 'Slider--',
            slideClass: 'Slider-item',
            slideBlankClass: 'is-blank',
            slideActiveClass: 'is-active',
            slideDuplicateActiveClass: 'is-duplicate-active',
            slideVisibleClass: 'is-visible',
            slideDuplicateClass: 'is-duplicate',
            slideNextClass: 'is-next',
            slideDuplicateNextClass: 'is-duplicate is-next',
            slidePrevClass: 'is-prev',
            slideDuplicatePrevClass: 'is-duplicate is-prev',
            wrapperClass: 'Slider-items'
        })
    })
}

const newDatasheets = () => {
    const datasheetLinks = document.querySelectorAll('.js-newDatasheets')

    datasheetLinks.forEach((link) => {
        var hasGivenEmail = Cookie.get('datasheet') || false
        let form

        link.addEventListener('click', (e) => {
            let url = e.target.dataset.url

            e.preventDefault()
            e.stopPropagation()

            if (!hasGivenEmail) {
                // Ask for email, show the popup
                link.click()
                form = document.getElementById(`${e.target.dataset.id}`)

                if (form) {
                    form.addEventListener(
                        'freeform-ajax-success',
                        function (e) {
                            e.preventDefault()
                            let sheetUrl = e.form.fieldSheetUrl.value

                            // Set a cookie
                            Cookie.set('datasheet', true, {expires: '4Y'})
                            hasGivenEmail = true

                            // Open up the url
                            window.open(url, '_blank')
                            location.reload()
                        }
                    )
                }
            } else {
                console.log('has given = true, open file ')
                // console.log(url)
                // Open up the url
                window.open(url, '_blank')
            }
        })
    })
}

const datasheet = () => {
    let datasheets = document.getElementsByClassName('js-datasheet')
    console.log(datasheets, 'datasheets');

    if (datasheets.length > 0) {
        var hasGivenEmail = Cookie.get('datasheet') || false

        Array.from(datasheets).forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()

                let url = e.target.dataset.url
                let sheetname = e.target.dataset.name

                if (!hasGivenEmail) {
                    // Fill in datasheet name
                    if (
                        document.querySelector('input[name="fieldDatasheetValue"]')
                    ) {
                        document.querySelector(
                            'input[name="fieldDatasheetValue"]'
                        ).value = sheetname
                    }

                    // Pass datasheet url to form
                    if (document.querySelector('input[name="fieldSheetUrl"]')) {
                        document.querySelector(
                            'input[name="fieldSheetUrl"]'
                        ).value = url
                    }

                    // Ask for email, show the popup
                    document.getElementById('js-showDataSheetModal').click()
                } else {
                    // Open up the url
                    window.open(url, '_blank')
                }
            })
        })

        const form = document.getElementById('js-datasheetForm')

        // Do something on a successful ajax submit
        if (form) {
            form.addEventListener('freeform-ajax-success', function (e) {
                e.preventDefault()
                let sheetUrl = e.response.values.fieldSheetUrl;

                // Set a cookie
                Cookie.set('datasheet', true, {expires: '4Y'})
                hasGivenEmail = true

                // Open up the url
                window.open(sheetUrl, '_blank')
                location.reload()
            })
        }
    }
}

var trackEvent = function (action, category, label, value) {
    if (typeof gtag === 'function') {
        if (!value) {
            value = ''
        }

        gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value
        })
    }
}

const gtaeventtracking = () => {
    /* event tracking for gtag google analytics tracker */
    var scrollEvents = function () {
        var scroll_pos = $(window).scrollTop()
        var wh = $(window).height()
        var dh = $(document).height()
        if (dh > wh) {
            if (scroll_pos + wh === dh && !first_time_end) {
                first_time_end = true
                trackEvent('scroll', 'Scroll', 'Page end')
            } else if (scroll_pos + wh >= dh / 2 && !first_time_half) {
                first_time_half = true
                trackEvent('scroll', 'Scroll', 'Page half')
            }
        }
    }
    var trackOneMinute = function () {
        setTimeout(function () {
            if (minutes_past < 10) {
                minutes_past++
                trackEvent(
                    'time',
                    'Time on site',
                    minutes_past + ' minutes past'
                )
                trackOneMinute()
            }
        }, 60000)
    }
    var trackContactLinks = function () {
        var cntlinks = $('a[href^="mailto:"],a[href^="tel:"]')
        if (cntlinks.length) {
            $('body').on(
                'mouseup',
                'a[href^="mailto:"],a[href^="tel:"]',
                function () {
                    var href = $(this).attr('href')
                    trackEvent('click', 'Contact', href)
                }
            )
        }
    }
    var trackExternalLinks = function () {
        var extlinks = $("a[target='_blank']")
        if (extlinks.length) {
            $('body').on('mouseup', "a[target='_blank']", function () {
                var label = $(this).attr('href')
                trackEvent('click', 'External', label)
            })
        }
    }
    var trackVideo = function () {
        var videos = $('video')
        if (videos.length) {
            $('video').on('pause', function (e) {
                var video = $(this).find('source:first').attr('src')
                var time = e.target.currentTime
                var label = video + ' (' + time + 's)'
                trackEvent('video', 'Pause', label)
            })
            $('video').on('play', function (e) {
                var video = $(this).find('source:first').attr('src')
                var time = e.target.currentTime
                var label = video + ' (' + time + 's)'
                trackEvent('video', 'Play', label)
            })
        }
    }

    const trackMailResultRubidor = () => {
        if (document.querySelector('.js-success-rubidor')) {
            trackEvent('form', 'Offerte', window.location.href)
        }
    }

    const trackMailResultSolidor = () => {
        if (document.querySelector('.js-success-solidor')) {
            trackEvent('form', 'Contact', window.location.href)
        }
    }

    var loadEvents = function () {
        trackOneMinute()
        trackContactLinks()
        trackExternalLinks()
        trackVideo()
        trackMailResultRubidor()
        trackMailResultSolidor()
    }

    loadEvents()
    //vars
    var first_time_end, first_time_half
    var minutes_past = 0

    $(window).on('scroll', scrollEvents)
}

const gridblock = () => {
    if (jQuery('.js-videoBlock').length > 0) {
        jQuery('.js-startVideo').on('click', function (event) {
            event.preventDefault()
            event.stopPropagation()

            let itemId = jQuery(this).data('item-id')
            let $block = jQuery('.js-videoBlock[data-item-id="' + itemId + '"]')
            if ($block && $block.find('video').length > 0) {
                $block.find('.Grid-videoOverlay').addClass('u-hidden')
                let video = $block.find('video').get(0)
                video.play()
            } else if ($block && $block.find('iframe').length > 0) {
                if (!$block.find('.Grid-videoOverlay').hasClass('u-hidden')) {
                    $block.find('.Grid-videoOverlay').addClass('u-hidden')
                    var $iframe = $block.find('iframe')
                    let src = $iframe.data('src')
                    if (
                        !src ||
                        typeof src == 'undefined' ||
                        src == 'undefined'
                    ) {
                        return
                    }

                    src += '&autoplay=1'

                    $iframe.attr('src', src)
                }
            }
        })
    }
}

const lightGalleryInit = () => {
    if (jQuery('.js-showGallery').length > 0) {
        jQuery('.js-showGallery').on('click', function (event) {
            event.preventDefault()
            event.stopPropagation()
            let urls = jQuery(this).parents('.js-overviewItem').data('urls')
            lightGallery(this, {
                dynamic: true,
                dynamicEl: urls,
                appendSubHtmlTo: '.lg-outer'
            })
        })
    }
}

const filter = () => {
    const tags = document.querySelectorAll('.Overview-tag Button')
    if (tags.length) {
        tags.forEach((tag) => {
            tag.addEventListener('click', () => {
                const items = document.querySelectorAll('.OverviewItem')
                let itemsFound = false
                const noItemsFoundDiv = document.querySelector(
                    '.Overview-noResults'
                )
                if (tag.classList.contains('is-active')) {
                    items.forEach((item) => {
                        item.parentNode.style.display = 'block'
                    })
                    tag.classList.remove('is-active')
                    itemsFound = true
                    tag.querySelector('svg').style.display = 'none'
                } else {
                    tags.forEach((tag) => {
                        tag.classList.remove('is-active')
                        tag.querySelector('svg').style.display = 'none'
                    })

                    tag.classList.add('is-active')
                    tag.querySelector('svg').style.display = 'flex'

                    const filter = tag.dataset.filter

                    if (noItemsFoundDiv) {
                        noItemsFoundDiv.style.display = 'none'
                    }

                    items.forEach((item) => {
                        item.parentNode.style.display = 'none'
                        const tags = item.dataset.filter.split(' ')

                        if (tags.includes(filter)) {
                            itemsFound = true
                            item.parentNode.style.display = 'block'
                        }
                    })
                }

                if (noItemsFoundDiv && !itemsFound) {
                    noItemsFoundDiv.style.display = 'block'
                }
            })
        })
    }
}

const productFilterPlainJs = () => {
    const productFilter = document.querySelectorAll('.productFilter')
    productFilter.forEach((filter) => {
        const products = filter.querySelectorAll('.productFilter-item')
        const noProductsFoundEl = filter.querySelector(
            '.productFilter-noProductsFound'
        )
        const parent = filter.querySelector('.productFilter-products')
        const startHeight = gsap.getProperty(parent, 'height')

        const groups = filter.querySelectorAll('.productFilter-group')
        let selectedCategories = []
        groups.forEach((group) => {
            selectedCategories.push({
                group: group.id,
                list: []
            })
        })

        const checkboxes = filter.querySelectorAll('.productFilter-checkboxes')

        checkboxes.forEach((cb) => {
            cb.addEventListener('change', (e) => {
                const cat = e.target.id

                const [group, optionFromCat] = cat.split('-')
                const option = optionFromCat.replace('option', '')

                const categoryToCheck = selectedCategories.filter(
                    (cat) => cat.group === group
                )

                if (categoryToCheck.length > 0) {
                    const clickedIndex = categoryToCheck[0].list.indexOf(option)

                    if (clickedIndex == -1) {
                        categoryToCheck[0].list.push(option)
                    } else {
                        categoryToCheck[0].list.splice(clickedIndex, 1)
                    }
                }

                filterProducts()
            })
        })

        function filterProducts() {
            const state = Flip.getState(
                filter.querySelectorAll('.productFilter-item')
            )
            let productsFound = false
            products.forEach((prod) => {
                let showProductArray = []

                selectedCategories.forEach((cat) => {
                    if (cat.list.length > 0) {
                        const options = prod.dataset.cat.split('|')
                        options.shift()

                        let catFound = false

                        options.forEach((option) => {
                            if (cat.list.includes(option)) {
                                catFound = true
                            }
                        })
                        showProductArray.push(catFound)
                    }
                })

                if (showProductArray.every((v) => v === true)) {
                    productsFound = true
                    // filteredProducts.push(`${prod.id}`)
                    prod.classList.add('productFilter-show')
                    prod.classList.remove('productFilter-hidden')
                } else {
                    prod.classList.remove('productFilter-show')
                    prod.classList.add('productFilter-hidden')
                }
            })

            if (productsFound) {
                noProductsFoundEl.classList.add('hidden')
            } else {
                noProductsFoundEl.classList.remove('hidden')
            }

            const endHeight = gsap.getProperty(parent, 'height')

            const flip = Flip.from(state, {
                duration: 0.5,
                ease: 'power1.inOut',
                stagger: 0.08,
                onEnter: (elements) =>
                    gsap.fromTo(
                        elements,
                        {opacity: 0},
                        {opacity: 1, duration: 1}
                    ),
                onLeave: (elements) =>
                    gsap.to(elements, {
                        opacity: 0,
                        duration: 1
                    })
            })
            // if (productsFound) {
            //     flip.fromTo(
            //         parent,
            //         {
            //             height: startHeight
            //         },
            //         {
            //             height: endHeight,
            //             clearProps: 'height',
            //             duration: flip.duration()
            //         },
            //         0
            //     )
            // }
        }
    })
}

const rubidorProducts = () => {
    const filters = document.querySelector('.rubidorProducts-filters')
    if (filters) {
        setTimeout(() => {
            filters.classList.add('rubidorProducts-filters--animate')
        }, 500)
    }

    Alpine.data('rubidorProducts', () => ({
        mobileFilterOpen: false,

        changeState(value) {
            this.mobileFilterOpen = value
            if (this.mobileFilterOpen == true) {
                document.querySelector('.Main').style.zIndex = 5
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('.Main').style.zIndex = 2
                document.querySelector('body').style.overflow = 'auto'
            }
        }
    }))
}

function LottieScrollTrigger(vars) {
    const startPos = window.innerWidth > 1024 ? '40%' : '65%'
    let playhead = {frame: 0},
        target = gsap.utils.toArray(vars.target)[0],
        speeds = {slow: '+=3000', medium: '+=1000', fast: '+=500'},
        st = {
            trigger: target,
            pin: false,
            // markers: true,
            start: `top ${startPos}`,
            end: speeds[vars.speed] || '+=1000',
            scrub: 1
        },
        ctx = gsap.context && gsap.context(),
        animation = lottie.loadAnimation({
            container: target,
            renderer: vars.renderer || 'svg',
            loop: false,
            autoplay: false,
            path: vars.path,
            rendererSettings: vars.rendererSettings || {
                preserveAspectRatio: 'xMidYMid slice'
            }
        })
    for (let p in vars) {
        // let users override the ScrollTrigger defaults
        st[p] = vars[p]
    }
    animation.addEventListener('DOMLoaded', function () {
        let createTween = function () {
            animation.frameTween = gsap.to(playhead, {
                frame: animation.totalFrames - 1,
                ease: 'none',
                onUpdate: () => animation.goToAndStop(playhead.frame, true),
                scrollTrigger: st
            })
            return () => animation.destroy && animation.destroy()
        }
        ctx && ctx.add ? ctx.add(createTween) : createTween()
        // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
        ScrollTrigger.sort()
        ScrollTrigger.refresh()
    })
    return animation
}

const lottieFilePlayer = () => {
    const lottieBlocks = document.querySelectorAll('.js-lottieFile')

    lottieBlocks.forEach((l) => {
        const url = l.dataset.url
        const container = l.querySelector('.js-lottieContainer')
        if (url && container) {
            LottieScrollTrigger({
                target: container,
                path: url,
                speed: 'fast',
                scrub: 1 // seconds it takes for the playhead to "catch up"
                // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See /docs/v3/Plugins/ScrollTrigger
            })
        }
    })
}

window.addEventListener('load', (event) => {
    asyncLoadSiteFonts()
    forEachPolyfill()
    form()
    gallery()
    // media();
    tabHandler()
    sliderInit()
    scrollTo()
    // tiles();
    map()
    modal()
    nav()
    banner()
    datasheet()
    newDatasheets()
    gtaeventtracking()
    gridblock()
    lightGalleryInit()
    productFilterPlainJs()
    rubidorProducts()
    lottieFilePlayer()

    setTimeout(() => {
        Alpine.start()
    }, 500)
})
